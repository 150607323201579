import axios from "axios";

export async function validateToken(token) {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/search/provider?type=DOCTOR&page`, {
      headers: { "authorization": `bearer ${token}` }
    });
    if (res.status === 200 ) {
      console.log("you already loggedIn");
      return  true;
    }
    return  false
  }catch (e){
    return  false;
  }

}
