import React, { useEffect, useState } from "react";
import Form, { FormIsLoading } from "../../../components/fouladyar/form";
import { useDispatch, useSelector } from "react-redux";
import { formStatics, formStructure } from "./index";
import axios from "axios";
import { convertDate } from "../../../shared/shared";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getItemById } from "../../../redux/store/services/asoo/teachers/store/teachers-actions";
import toast from "react-hot-toast";
import { ErrorToaster } from "../../../shared/toaster";
import { useTranslation } from "react-i18next";
import { ConvertFilterObjectToUrlParam } from "../../../redux/store/shared/shared";



function ProviderAdd({ ...props }) {

  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const isEditing = location.pathname.includes("provider-edit");
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const path = '/Persons/Teacher'
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [isLoading, setIsloading] = useState(isEditing ? true : false);


  // subjectId	"636d2f2d-6d2b-4e25-8f8b-feac2533672a"
// email	"Lea.Giroux@gmail.com"
// type	"DOCTOR"
// gender	"FEMALE"
// firstName	"Léa"
// lastName	"Giroux"
// dateOfBirth	"1973-04-09"
// mobileNumber	"0690123456"
// specialization	"ENDOCRINOLOGY_DIABETOLOGY"
// rppsNumber	"3210987654"
// adeliNumber	"null"
// officeAddress	"14 Rue de la Pompe, 35000 Rennes"
// image	"798e0353-bdde-4f73-8c90-20bbadd8e196.jpg"
// paymentTypes	[ "BANK_CARD", "CHECK", "SPECIES" ]
// zipCode	"35064"
// note	""
// acceptVitalCard	false
// canDoPediatrics	false
// canDoAbortion	false
// canDoUltrasound	false
// doctorOrProfessor	"PROFESSOR"
// officePhoneNumber	"632109876"
// confirmedByAdmin	false
  const [data, setData] = useState({
    "firstName": "",
    "lastName": "",
    "gender": "",
    "type": "",
    "officeAddress": "",
    "specialization": "",
    "mobileNumber": "",
    "email": "",
    "dateOfBirth": "",
    "zipCode": "",
    "paymentTypes": "",
    "acceptVitalCard": "",
    "canDoPediatrics": "",
    "canDoAbortion": "",
    "canDoUltrasound": "",
    "doctorOrProfessor": "",
    "officePhoneNumber": "",
    "rppsNumber": "",
    "adeliNumber": "",
    "entryCardImagePreview": "",
  });

  async function getItemById(){
    try {
      console.log('providerId', id)
      return (await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/account/${id}`,{
        headers: {
          authorization: `bearer ${auth.token}`
        }
      }));
    } catch (e) {
      ErrorToaster(e, t)
    }
  }

  async function loadData() {
    try {
      const res = await getItemById();
      if (res.status === 200) {
        setData((prevData) => ({
          ...prevData,
          ...res.data,
          dateOfBirth: res.data.birthDate ? new Date(res.data.birthDate) : '',
        }));
        setIsloading(false);
        return data;
      }

    }catch (e){
      toast.error(e.response.data.error || (e.response.data.message || e.message))
    }


  }


  useEffect(() => {
    if (isEditing)
      loadData();
  }, []);


  function checkBirthDate(date){
    const currentDate = new Date();
    // Calculate the date 8 years ago
    const eightYearsAgo = new Date();
    eightYearsAgo.setFullYear(currentDate.getFullYear() - 8);

    // Get the current date
    if(date > eightYearsAgo){
      return false
    }


    return true
  }


  function checkIsRequiredFields() {

    const ErrorMessages = [];

    if(!data.firstName)
      ErrorMessages.push("فیلد اسم اجباری است");
    if(!data.lastName)



    if(ErrorMessages.length > 0){
      toast.error(ErrorMessages.join('\n'))
      return false
    }

    return true
  }
  async function onCreate() {
    try {

      if(checkIsRequiredFields()){
        const res = await axios.post(`${process.env.REACT_APP_API_URL}${path}`,
          {
            ...data,
            "birthDate": data.birthDate === '' ? null : convertDate(data.birthDate),
            "licenceDate": data.licenceDate === '' ? null : convertDate(data.licenceDate),
            "medicalDate": data.medicalDate === '' ? null :  convertDate(data.medicalDate),
            "entryCardDate": data.entryCardDate === '' ? null :  convertDate(data.entryCardDate),
          }, {
            headers: {
              "authorization": `bearer ${auth.token}`
            }
          }
        );

        if(res.data.statusCode === 201) {
          if(data.personalImage.length > 0){
            await uploadImage({
              file: data.personalImage[0].file,
              user: res.data.data.user.id,
              type: 1
            })
            console.log('personalImage uploaded!', res.data.data)
          }

          if(data.nationalCardImage.length > 0){
            await uploadImage({
              file: data.nationalCardImage[0].file,
              user: res.data.data.user.id,
              type: 4
            })
            console.log('nationalCardImage uploaded!', res.data.data)
          }

          if(data.entryCardImage.length > 0){
            await uploadImage({
              file: data.entryCardImage[0].file,
              user: res.data.data.user.id,
              type: 5
            })
            console.log('entryCardImage uploaded!', res.data.data)
          }

          navigate(`/teacher-list`);
          setProcessing(false)
        }
      }



    } catch (e) {
      ErrorToaster(e, t)
      setProcessing(false)
    }
  }

  async function uploadImage({file, user, type}) {
    try {
      setProcessing(true)


      const res = await axios.post(`${process.env.REACT_APP_API_URL}/Files/file`,
        {
          file: file,
          type: type, // اجازه نامه پرواز
          user: user // بعد از ایجاد هواپیما، ایدی ایجاد شده را در اینجا ست کن
        }, {
          headers: {
            "authorization": `bearer ${auth.token}`,
            "Content-Type": "multipart/form-data"
          },

        }
      );

      console.log('fileUpload',res)
    } catch (e) {
      ErrorToaster(e, t)
    }
  }

  async function onUpdate() {
    try {

      setProcessing(true)
      if(checkIsRequiredFields()){
        let _data = {...data}
        delete _data.personalImage;
        delete _data.personalImagePreview;
        delete _data.nationalCardImage;
        delete _data.nationalCardImagePreview;
        delete _data.entryCardImage;
        delete _data.entryCardImagePreview;


        const res = await axios.put(`${process.env.REACT_APP_API_URL}${path}`,
          {
            ..._data,
            "birthDate": _data.birthDate === '' ? null : convertDate(_data.birthDate),
            "licenceDate": _data.licenceDate === '' ? null : convertDate(_data.licenceDate),
            "medicalDate": _data.medicalDate === '' ? null : convertDate(_data.medicalDate),
            "entryCardDate": _data.entryCardDate === '' ? null : convertDate(_data.entryCardDate),
          }, {
            headers: {
              "authorization": `bearer ${auth.token}`
            }
          }
        );

        console.log('res', res)
        if(res.data.statusCode === 200) {


          if(data.personalImage.length > 0){
            await uploadImage({
              file: data.personalImage[0].file,
              user: res.data.data.user.id,
              type: 1
            })
          }

          if(data.nationalCardImage.length > 0){
            await uploadImage({
              file: data.nationalCardImage[0].file,
              user: res.data.data.user.id,
              type: 4
            })
          }

          if(data.entryCardImage.length > 0){
            await uploadImage({
              file: data.entryCardImage[0].file,
              user: res.data.data.user.id,
              type: 5
            })
          }

          navigate(`/teacher-list`);
        }

      }


    } catch (e) {
      ErrorToaster(e, t)
    }
  }

  function handleOnFieldChange(change) {
    setData((prevData) => ({
      ...prevData,
      ...change
    }));
  }

  async function handleOnSubmit() {
    console.log("form", data);
    !isEditing ? await onCreate() : await onUpdate();

  }

  return (

    <>
      {
        isLoading ?
          <FormIsLoading
            statics={formStatics}
            isEditing={isEditing}
          />
          :
          <Form
            form={data}
            isEditing={isEditing}
            fields={formStructure}
            statics={formStatics}
            isloading={processing}
            onFieldChange={handleOnFieldChange}
            onFormSubmit={handleOnSubmit}
          />
      }
    </>


  );
};

export default ProviderAdd;
