import React, { useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Layout from "../layout/Index";
import AsooLogin from "../pages/asoo/auth/asoo-login";
import Profile from "../pages/asoo/profile";
import Error404Classic from "../pages/asoo/error/404-classic";
import Error504Classic from "../pages/asoo/error/504-classic";
import ProviderList from "../pages/cliconsult/provider/providerList";
import ProviderAdd from "../pages/cliconsult/provider/providerAdd";
import AppointmentsList from "../pages/cliconsult/appointments/appointmentsList";
import SessionList from "../pages/cliconsult/sessions/sessionList";
import SessionAdd from "../pages/cliconsult/sessions/sessionAdd";
import CliconsultHome from "../pages/cliconsult/home";
import PatientList from "../pages/cliconsult/patient/patientList";
import Support from "../pages/cliconsult/support/support";
import SupportList from "../pages/cliconsult/support/supportList";


const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="" element={<Layout />}>
        {/*<Route index element={<Homepage />}></Route>*/}
        <Route index element={<CliconsultHome />}></Route>

        {/*Cliconsult*/}
        <Route>
          <Route path="provider-list/:provider" element={<ProviderList />}></Route>
          <Route path="provider-edit/:id" element={<ProviderAdd />}></Route>
          <Route path="provider/:id" element={<Profile />}></Route>
        </Route>
        <Route>
          <Route path="patient-list" element={<PatientList />}></Route>
        </Route>

        <Route>
          <Route path="appointment-list/:providerId" element={<AppointmentsList />}></Route>
        </Route>
        <Route>
          <Route path="support/:issueDate/:email" element={<Support />}></Route>
          <Route path="support-list" element={<SupportList />}></Route>
        </Route>

        <Route>
          <Route path="session-list/:providerId" element={<SessionList />}></Route>
          <Route path="session-add/:providerId/:date" element={<SessionAdd />}></Route>
          <Route path="session-edit/:providerId/:date/:sessionId" element={<SessionAdd />}></Route>
        </Route>


        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="settings" element={<Settings />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route path="asoo_home" element={<AsooHome />}></Route>*/}
        {/*<Route>*/}
        {/*  <Route path="user-list-2" element={<UserList2 />}></Route>*/}
        {/*  <Route path="user-add-2" element={<UserAdd2 />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="plane-list" element={<PlaneList />}></Route>*/}
        {/*  <Route path="plane-add" element={<PlaneAdd />}></Route>*/}
        {/*  <Route path="plane-edit/:id" element={<PlaneAdd />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="teacher-list" element={<TeacherList />}></Route>*/}
        {/*  <Route path="teacher-add" element={<TeacherAdd />}></Route>*/}
        {/*  <Route path="teacher-edit/:id" element={<TeacherAdd />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="student-add" element={<StudentAdd />}></Route>*/}
        {/*  <Route path="student-list" element={<StudentList />}></Route>*/}
        {/*  <Route path="student-edit/:id" element={<StudentAdd />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="service-add" element={<ServiceAdd />}></Route>*/}
        {/*  <Route path="service-list" element={<ServiceList />}></Route>*/}
        {/*  <Route path="service-edit/:id" element={<ServiceAdd />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="flight-list" element={<FlightList />}></Route>*/}
        {/*  <Route path="flight-add" element={<FlightAdd />}></Route>*/}
        {/*  <Route path="flight-edit/:id" element={<FlightAdd />}></Route>*/}
        {/*  <Route path="flight-service-list" element={<FlightServicesList />}></Route>*/}
        {/*  <Route path="flight-service-add" element={<FlightServicesAdd />}></Route>*/}
        {/*  <Route path="flight-service-edit/:id" element={<FlightServicesAdd />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="course-list" element={<CourseList />}></Route>*/}
        {/*  <Route path="course-add" element={<CourseAdd />}></Route>*/}
        {/*  <Route path="course-edit/:id" element={<CourseAdd />}></Route>*/}
        {/*  <Route path="course-students/:courseId" element={<CourseStudents />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="course-class-list" element={<ClassList />}></Route>*/}
        {/*  <Route path="course-class-add" element={<ClassAdd />}></Route>*/}
        {/*  <Route path="course-class-edit/:id" element={<ClassAdd />}></Route>*/}
        {/*  <Route path="course-class-presence/:courseId/:classId" element={<ClassPresence />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="notifications" element={<NotificationsList />}></Route>*/}
        {/*</Route>*/}


        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="profile/:role/:id" element={<Profile />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="transaction-list" element={<TransactionList />}></Route>*/}
        {/*  <Route path="recharge-wallet" element={<TransactionList />}></Route>*/}
        {/*  <Route path="checkout" element={<Checkout />}></Route>*/}
        {/*  <Route path="recharge" element={<Recharge />}></Route>*/}
        {/*</Route>*/}

        {/*/!*Asoo*!/*/}
        {/*<Route>*/}
        {/*  <Route path="add-student-to-course/:courseId" element={<StudentList />}></Route>*/}
        {/*</Route>*/}
      </Route>
      {/*asoo*/}
      <Route path="login" element={<AsooLogin />}></Route>
      <Route path="errors">
        <Route path="404-classic" element={<Error404Classic />}></Route>
        <Route path="504-classic" element={<Error504Classic />}></Route>
      </Route>
      <Route path="*" element={<Error404Classic />}></Route>


    </Routes>
  );
};
export default Pages;
