import React, { useEffect, useMemo, useState } from "react";
import Table from "../../../components/fouladyar/table";
import { useDispatch, useSelector } from "react-redux";
import { filterStructure, tableStatics, tableStructure } from "./index";
import { ConvertFilterObjectToUrlParam } from "../../../redux/store/shared/shared";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ErrorToaster } from "../../../shared/toaster";
import { useTranslation } from "react-i18next";






const ProviderList = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const { provider } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null)
  const [prevPage, setPrevPage] = useState(null)
  const [providerId, setProviderId] = useState(provider)
  const [pagination, setPagination] = useState({
    itemPerPage: 7,
    currentPage: 1,
    totalItems: 0,
    lastUpdateBy: '',

    //Cliconsult pagination style
    pageId: '',

  })

  const [filter, setFilter] = useState({})
  async function getItem(){
    try {
      console.log('provider', provider)
      return (await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/search/provider?type=${provider}${pagination.currentPage === 1 ? '' : '&page='}${pagination.currentPage === 1 ? '' : pagination.pageId}${Object.keys(filter).length > 0 ? '&' : ''}${ConvertFilterObjectToUrlParam(filter)}`,{
        headers: {
          authorization: `bearer ${auth.token}`
        }
      }));
    } catch (e) {
      ErrorToaster(e, t)
    }
  }
  async function initializeData() {
    setIsLoading(true)
    const res = await getItem()

    console.log('res-initialdata', res);
    if(res.status === 200){
      console.log('initialdata', res.data);
      setData(res.data.providers)
      //nextPage previousPage
      setPrevPage(res.data.previousPage)
      setNextPage(res.data.nextPage)
      // setPagination({...pagination, totalItems: res.count || 1, lastUpdateBy: 'initializer'})
    }
    setIsLoading(false)
  }

  async function fetchData(updater) {

    try {
      setIsLoading(true)
      const res = await getItem()

      console.log('res-fetchData', res);
      if(res.status === 200){
        console.log('fetchData', res.data);
        setData(res.data.providers)
        setPrevPage(res.data.previousPage)
        setNextPage(res.data.nextPage)

        if(updater === 'filter')
          setPagination({
            ...pagination,
            totalItems: res.data.count,
            currentPage: 1,
            lastUpdateBy: updater
          })
      }
      setIsLoading(false)
    }catch (e){
      toast.error(e.response.data.error || (e.response.data.message || e.message))
    }


  }

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    initializeData();
  }, [provider]);


  useEffect(() => {
    console.log(pagination);
    if(pagination.lastUpdateBy === "pagination")
        fetchData('pagination');

  }, [pagination]);



  useEffect(() => {
    console.log('filter', filter)
    fetchData('filter');
  }, [filter]);



  return (
    <React.Fragment>
      <Table
        loading={isLoading}
        filter={filter}
        tableData={data}
        pagination={pagination}
        pageIds={{
          nextPage: nextPage,
          prevPage: prevPage
        }}
        tableHeading={tableStatics}
        tableStructure={tableStructure}
        filterStructure={filterStructure}
        onItemPerPageChange={(itemPerPage, currentPage)=> {
          setPagination({...pagination, itemPerPage: itemPerPage, currentPage: currentPage, lastUpdateBy: 'pagination'})
        }}
        onCurrentPageChange={(currentPage)=> {
          setPagination(prevState => ({
            ...prevState,
            currentPage: currentPage,
            lastUpdateBy: 'pagination',
            pageId: prevState.currentPage < currentPage ? nextPage : prevPage
          }))

        }}
        onFilterSubmit={ (e)=>{
          setFilter(e);
        }}
        onDeleteComplete={ (e)=>{
          fetchData('pagination');
        }}
      />
    </React.Fragment>
  );
};

export default ProviderList;
