import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Field } from "../../../components/fouladyar/field/field";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from "../../../components/block/Block";
import { ErrorToaster } from "../../../shared/toaster";


const Support = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState({
    pattern: "-",
    customerName: "-",
    message: "-"
  });
  // const [date, setDate] = useState("");
  // const [email, setEmail] = useState("");
  const { issueDate, email } = useParams();
  const [isLoading, setIsLoading] = useState(true);




  useEffect(()=>{
    async function onSearch() {

      try {
        setIsLoading(true);
        console.log('api/v1/admin/contactus?email=${email}&date=${getDate()', `api/v1/admin/contactus?email=${email}&date=${getDate()}` )
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/contactus?email=${email}&date=${getDate()}`, {
          headers: {
            authorization: `bearer ${auth.token}`
          }
        });

        console.log("res", res);
        if (res.status === 200) {
          setMessage(res.data[0]);
          // console.log('fetchData', res.data);
        }
        setIsLoading(false);
      } catch (e) {
        ErrorToaster(e, t)
      }


    }
    onSearch()
  },[])


  function getDate() {
    const currentDate = new Date(issueDate);
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    return formattedDate;
  }

  function ProfileInfo() {

    function ProfileInfoItem({ title, value }) {
      return (
        <div class="col-sm-6 col-md-6 col-lg-4">
          <span class="sub-text">{title}:</span>
          <span>{value}</span>
        </div>
      );
    }

    return (
      <div class="card card-bordered">
        <div class="card-inner-group">


          <div class="card-inner">
            <div class="row g-3">
              <ProfileInfoItem title={"Customer Name"} value={message.customerName} />
              <ProfileInfoItem title={"Message"} value={message.pattern} />
            </div>
            <br />
            <div class="row g-3">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <span class="sub-text">Message</span>
                <span>{message.message}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="p-4">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="font-weight-700">Support</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      </div>
      <div>
        <div className="row p-4">
          <div className="p-2 col-5 ">
            <Field
              id={"date"}
              name={"date"}
              label={"Date"}
              disabled={true}
              placeholder={"Please Select date"}
              type={"date"}
              value={issueDate}
              onChange={(e) => {
                // setDate(e);
              }}
            />
          </div>
          <div className="p-2 col-5 ">
            <Field
              id={"email"}
              name={"email"}
              disabled={true}
              label={"email"}
              type={"text"}
              placeholder={"Please enter email"}
              value={email}
              onChange={(e) => {
                // setEmail(e);
              }}
            />
          </div>
          <div className={`col-2 disabled`} onClick={async () => {
            // if (issueDate && email)
              // await onSearch();
          }}>
            <div class="btn btn-primary w-100 justify-content-center"
                 style={{ padding: "10px 0px", marginTop: "43px" }}>Search
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-12 col-xl-12 col-xxl-12 p-4">
        <ProfileInfo />

      </div>
    </React.Fragment>
  );
};

export default Support;
