import React, { useEffect, useMemo, useState } from "react";
import Table from "../../../components/fouladyar/table";
import { useDispatch, useSelector } from "react-redux";
import { filterStructure, tableStatics, tableStructure } from "./index";
import { ConvertFilterObjectToUrlParam } from "../../../redux/store/shared/shared";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ErrorToaster } from "../../../shared/toaster";
import { useTranslation } from "react-i18next";






const SupportList = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null)
  const [prevPage, setPrevPage] = useState(null)

  const [filter, setFilter] = useState({})
  async function getItem(){
    try {
      console.log('filter', filter)
      return (await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/contactus?${Object.keys(filter).length > 0 ? '&' : ''}${ConvertFilterObjectToUrlParam(filter)}`,{
        headers: {
          authorization: `bearer ${auth.token}`
        },
      }));
    } catch (e) {
      ErrorToaster(e, t)
    }
  }
  async function initializeData() {
    setIsLoading(true)
    const res = await getItem()

    console.log('res-initialdata', res);
    if(res.status === 200){
      setData(res.data)
    }
    setIsLoading(false)
  }

  async function fetchData(updater) {

    try {
      setIsLoading(true)
      const res = await getItem()

      console.log('res-fetchData', res);
      if(res.status === 200){
        console.log('fetchData', res.data);
        setData(res.data)
      }
      setIsLoading(false)
    }catch (e){
      toast.error(e.response.data.error || (e.response.data.message || e.message))
    }


  }

  useEffect(() => {
    initializeData();
  }, []);

  function getDate(date) {
    const currentDate = date;
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    return formattedDate;
  }




  useEffect(() => {
    console.log('filter', filter)
    fetchData('filter');
  }, [filter]);



  return (
    <React.Fragment>
      <Table
        loading={isLoading}
        filter={filter}
        tableData={data}
        pageIds={{
          nextPage: nextPage,
          prevPage: prevPage
        }}
        tableHeading={tableStatics}
        tableStructure={tableStructure}
        filterStructure={filterStructure}
        onItemPerPageChange={(itemPerPage, currentPage)=> {
        }}
        onCurrentPageChange={(currentPage)=> {


        }}
        onFilterSubmit={ (e)=>{
          const keys = Object.keys(e);
          console.log('keys', keys);
          let newList = {...e};
          if(keys.includes('date'))
            newList.date = getDate(e.date);
          setFilter(newList);
        }}
        onDeleteComplete={async (e)=>{

        }}
      />
    </React.Fragment>
  );
};

export default SupportList;
