import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { LoadingState } from "../../../components/fouladyar/loading-state/loadingState";
import { EmptyState } from "../../../components/fouladyar/empty-state/emptyState";
import { LuUser } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import { ErrorToaster } from "../../../shared/toaster";
import moment from "moment/moment";


const Profile = ({}) => {
  const { t, i18n } = useTranslation();
  const { role, id } = useParams();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const [pagination, setPagination] = useState({
    itemPerPage: 7,
    currentPage: 1,
    totalItems: 0,
    lastUpdateBy: ""
  });


  const [user, setUser] = useState();
  const [image, setImage] = useState();


  useEffect(() => {
    async function loadProfile() {
      try {
        setIsLoading(true);
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/account/${id}`, {
          headers: { "authorization": `bearer ${auth.token}` }
        });
        if (res.status === 200) {
          let newData = {...res.data};
          setImage(res.data.image);

          delete newData.image;
          delete newData.subjectId;
          setUser(newData);

        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        ErrorToaster(e, t);
      }
    }

    loadProfile();
  }, []);


  function Header({ title, description }) {
    return (
      <div class="nk-block-head-content">
        <h3 class="nk-block-title page-title font-weight-700">{title}</h3>
        <div class="nk-block-des text-soft">
          <p>{description}</p>
        </div>
      </div>
    );
  }


  function ProfileInfo() {

    function ProfileInfoItem({ title, value }) {

      if (title === "birthDate" || title === "entryCardDate" || title === "createdAt" || title === "updatedAt" || title === "deletedAt") {
        return (
          <div class="col-sm-6 col-md-6 col-lg-4">
            <span class="sub-text">{title}:</span>
            <span>{new Date(value)}</span>
          </div>
        );
      }
      if (title === "medicalDate" || title === "licenceDate") {
        const gregorianMoment = moment(new Date(value), "YYYY-MM-DD HH:mm:ss");

        return (
          <div class="col-sm-6 col-md-6 col-lg-4">
            <span class="sub-text">{title}:</span>
            <span>{gregorianMoment.format("YYYY/MM/DD")}</span>
          </div>
        );
      }
      if (title === "email") {
        return (
          <div class="col-sm-6 col-md-6 col-lg-4">
            <span class="sub-text">{title}:</span>
            <span>{value || "-"}</span>
          </div>
        );
      }


      if (title === "image" ) {

        return (
          <div class="col-sm-6 col-md-6 col-lg-4">
            <span class="sub-text">{title}:</span>
            <span className="profile-images">
              <img className="header-profile-image" src={`${process.env.REACT_APP_S3_BUCKET}/${value}` } alt={"image"}/>
            </span>
          </div>
        );
      }
      return (
        <div class="col-sm-6 col-md-6 col-lg-4">
          <span class="sub-text">{title}:</span>
          <span>{value}</span>
        </div>
      );
    }

    return (
      <div class="card card-bordered">
        <div class="card-inner-group">
          <div class="card-inner">
            <div class="user-card user-card-s2">
              <div class="user-avatar lg bg-primary">
                <img className="header-profile-image" src={`${process.env.REACT_APP_S3_BUCKET}/${image}` } alt={"image"}/>
              </div>
              <div class="user-info">
                <div class="badge bg-light rounded-pill ucap">{user.role}</div>
                <h5>{`${user.firstName} ${user.lastName}`}</h5>
                <span class="sub-text">{user.email}</span>
              </div>
            </div>
          </div>

          {/*<div class="card-inner card-inner-sm">*/}
          {/*  <ul class="btn-toolbar justify-center gx-1">*/}
          {/*    <li><a href="#" className="btn btn-trigger btn-icon"><em*/}
          {/*      class="icon ni ni-shield-off"></em></a></li>*/}
          {/*    <li><a href="#" className="btn btn-trigger btn-icon"><em class="icon ni ni-mail"></em></a>*/}
          {/*    </li>*/}
          {/*    <li><a href="#" className="btn btn-trigger btn-icon"><em*/}
          {/*      class="icon ni ni-bookmark"></em></a></li>*/}
          {/*    <li><a href="#" className="btn btn-trigger btn-icon text-danger"><em*/}
          {/*      class="icon ni ni-na"></em></a></li>*/}
          {/*  </ul>*/}
          {/*</div>*/}

          {/*<div class="card-inner">*/}
          {/*  <div class="row text-center">*/}
          {/*    <div class="col-4">*/}
          {/*      <div class="profile-stats">*/}
          {/*        <span class="amount">23</span>*/}
          {/*        <span class="sub-text">Total Order</span>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div class="col-4">*/}
          {/*      <div class="profile-stats">*/}
          {/*        <span class="amount">20</span>*/}
          {/*        <span class="sub-text">Complete</span>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div class="col-4">*/}
          {/*      <div class="profile-stats">*/}
          {/*        <span class="amount">3</span>*/}
          {/*        <span class="sub-text">Progress</span>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}


          <div class="card-inner">
            <div class="row g-3">
              {
                Object.keys(user).map(key => {
                  console.log("key", key, user[key]);
                  if (user[key])
                    return (
                      <ProfileInfoItem title={key} value={user[key]} />
                    );
                })
              }
              {/*<div class="col-sm-6 col-md-4 col-lg-12">*/}
              {/*  <span class="sub-text">User ID:</span>*/}
              {/*  <span>UD003054</span>*/}
              {/*</div>*/}
              {/*<div class="col-sm-6 col-md-4 col-lg-12">*/}
              {/*  <span class="sub-text">Billing Email:</span>*/}
              {/*  <span>billing@softnio.com</span>*/}
              {/*</div>*/}
              {/*<div class="col-sm-6 col-md-4 col-lg-12">*/}
              {/*  <span class="sub-text">Billing Address:</span>*/}
              {/*  <span>551 Swanston Street, Melbourne <br /> Victoria 3053 Australia</span>*/}
              {/*</div>*/}
              {/*<div class="col-sm-6 col-md-4 col-lg-12">*/}
              {/*  <span class="sub-text">Language:</span>*/}
              {/*  <span>English, France</span>*/}
              {/*</div>*/}
              {/*<div class="col-sm-6 col-md-4 col-lg-12">*/}
              {/*  <span class="sub-text">Last Login:</span>*/}
              {/*  <span>15 Feb, 2019 01:02 PM</span>*/}
              {/*</div>*/}
              {/*<div class="col-sm-6 col-md-4 col-lg-12">*/}
              {/*  <span class="sub-text">KYC Status:</span>*/}
              {/*  <span class="lead-text text-success">Approved</span>*/}
              {/*</div>*/}
              {/*<div class="col-sm-6 col-md-4 col-lg-12">*/}
              {/*  <span class="sub-text">Register At:</span>*/}
              {/*  <span>Nov 24, 2019</span>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }


  return (
    <React.Fragment>

      <div class="nk-content ">
        <div class="container">
          <div class="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <Header title={"Profile"} description={""} />
                </div>
              </div>
              <div class="nk-block">
                <div class="row g-gs">
                  <div class="col-lg-12 col-xl-12 col-xxl-12">
                    {
                      isLoading ? <LoadingState /> : (
                        !user ? <EmptyState
                            icon={<LuUser size={38} color={"#737373"} />}
                            title={"کاربر مورد نظر یافت نشد"}
                            content={"کاربر مورد نظر یافت نشد. لطفا مجددا تلاش کرده یا به پشتیبانی اطلاع دهید"} /> :
                          <ProfileInfo />
                      )
                    }
                  </div>
                  {/*<div class="col-lg-8 col-xl-8 col-xxl-9">*/}
                  {/*  <ProfileGrid/>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
