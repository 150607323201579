import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Badge, Card } from "reactstrap";
import { Field } from "../../../../components/fouladyar/field/field";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from "../../../../components/Component";
import { LuMoreHorizontal } from "react-icons/lu";
import { MdAdd } from "react-icons/md";
import { CgSearchLoading } from "react-icons/cg";

function ProviderSelector({ provider, date, sessions,selectedSession, onDateChange, onSessionChange, ...props }) {

  const { t, i18n } = useTranslation();
  const [item, setItem] = useState(false);

  function TableHeader() {
    return (
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle className="font-weight-700">{'Provider'}</BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    );
  }

  function InfoItem({title, value}){
    return(
      <div className="d-flex flex-column w-100" >
        <div className="font-weight-700" >{title}</div>
        <div>{value}</div>
      </div>
    )
  }

  return (

    <Block className="m-2 mt-4">
      <TableHeader/>
      <Card className="card-bordered">
        <div className="card-inner-group">
          <div className="card-inner p-2">

            <div className="d-flex flex-row">
              <div class="d-flex flex-row" style={{width: "15rem"}}>
                  <span className="profile-images" style={{height:'6rem'}}>
                    <img className="header-profile-image profile-in-appointment-list" src={`${process.env.REACT_APP_S3_BUCKET}/${provider?.image}` } alt={"image"}/>
                  </span>
              </div>
              <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row mb-2">
                  <InfoItem title={'Full Name'} value={`${provider?.firstName} ${provider?.lastName}`}/>
                  <InfoItem title={'Subject Id'} value={`${provider?.subjectId} `}/>
                </div>
                <div className="d-flex flex-row" >
                  <InfoItem title={'RPPS Number'} value={`${provider?.rppsNumber} `}/>
                  <InfoItem title={'ADELI Number'} value={`${provider?.adeliNumber} `}/>

                </div>
              </div>
            </div>

            <li className="divider" />
            <div className="d-flex flex-row">

              <div className="p-2 w-100">
                <Field
                  id={"date"}
                  name={"date"}
                  label={"Date"}
                  placeholder={"Please Select date"}
                  type={"date"}
                  value={date}
                  onChange={(e) => {
                    onDateChange(e);
                  }}
                />
              </div>
              <div className="p-2 w-100">
                <Field
                  className="p-1"
                  id={"sessions"}
                  name={"sessions"}
                  label={"Sessions"}
                  type={"select"}
                  disabled={item?.disabled ?? false}
                  placeholder={"Please select a session"}
                  options={!sessions  ? [] : sessions?.map(i => {
                    return(
                      {label: `${i.start} - ${i.end}`, value: i.id}
                    )
                  })}
                  value={selectedSession}
                  onChange={(e) => {
                    onSessionChange(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Block>

  );
};

export default ProviderSelector;
