import React, { useState } from "react";
import { toFarsiNumber } from "../../../shared/toFarsiNumber";
import { ConvertDateToCalendarString } from "../../../shared/convertDateToCalendarString";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AudienceOverview from "./charts/audience-overview/AudienceOverview";
import { PreviewAltCard } from "../../../components/preview/Preview";
import { Col } from "../../../components/grid/Grid";
import Courses from "../../../assets/images/asoo/main-menu/courses.png";
import Classes from "../../../assets/images/asoo/main-menu/classes.png";
import Flights from "../../../assets/images/asoo/main-menu/flights.png";
import Services from "../../../assets/images/asoo/main-menu/services.png";
import Teachers from "../../../assets/images/asoo/main-menu/teachers.png";
import Student from "../../../assets/images/asoo/main-menu/students.png";
import Support from "../../../assets/images/asoo/main-menu/support.png";
import { LuCopy } from "react-icons/lu";
import { IoChevronBack } from "react-icons/io5";
import { Button } from "reactstrap";
import { RiNurseLine } from "react-icons/ri";
import { LiaUserNurseSolid } from "react-icons/lia";
import { FaUsersViewfinder } from "react-icons/fa6";
import { HiOutlineSupport } from "react-icons/hi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { TbClock2 } from "react-icons/tb";

const CliconsultHome = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [dd, setdd] = useState("30");





  function SupportSegment({}){
    return(
      <div className="p-2  col-12 ">
        <Link to={`link`} className="card card-bordered text-soft main-menu-item p-2">
          <div className="d-flex flex-row justify-content-between align-items-center">

            <div className="d-flex flex-row">
              <div className=" main-menu-item-image">
                <BiMessageSquareDetail  size={fontSize} color={color} />
              </div>
              <div>
                <div className="main-menu-item-content font-weight-700">
                  <div className="main-menu-item-title pt-3 pb-1">Contact Supoort</div>
                </div>

                <div className="row">
                  <p>You can Report any bug or problem to developer team through this email</p>
                </div>
              </div>
            </div>
            <Button disabled={true} color="primary" className="support-button p-0 ps-2 pe-2" onClick={(ev) => {
              ev.preventDefault();
            }}>
              Contact Support
            </Button>
          </div>
        </Link>
      </div>
    )
  }

  function MainMenuHeader({title}){
    return(
      <div className="main-menu-header">
        <div className="main-menu-header-title">{title}</div>
      </div>
    )
  }
  const fontSize = 35;
  const color = "#6e82a5";

  const menu1 = [
    {
      title: 'Doctors',
      description: 'you can search and find Doctors through Admin Dashboard',
      icon: <RiNurseLine size={fontSize} color={color} />,
      link: '/provider-list/DOCTOR'
    },
    {
      title: 'Nurses',
      description: 'you can search and find Nurses through Admin Dashboard',
      icon:<LiaUserNurseSolid size={fontSize} color={color} />,
      link: '/provider-list/NURSE'
    },
    {
      title: 'Midwifes',
      description: 'you can search and find Midwifes through Admin Dashboard',
      icon: <RiNurseLine size={fontSize} color={color} />,
      link: '/provider-list/MIDWIFE'
    },
    {
      title: 'Appointments',
      description: 'You can access all doctor appointments by searching doctor then click on appointments button',
      icon: <TbClock2   size={fontSize} color={color} />,
      link: '/provider-list/DOCTOR'
    },
  ]
  const menu2 = [
    {
      title: 'Patients',
      description: 'you can search and find Patient using this ',
      icon:<FaUsersViewfinder size={fontSize} color={color} />,
      link: '/patient-list'
    },
    {
      title: 'Support',
      description: 'You can access to all tickets here',
      icon:<HiOutlineSupport  size={fontSize} color={color} />,
      link: '/student-list'
    },
  ]

  function MainMenu({items}){


    function MenuItem({ item }){
      return(

        <div className="p-2  col-12 col-md-4 col-lg-3 ">
          <Link to={item.link} className="card card-bordered text-soft main-menu-item main-menu-item-height p-2">
            <div className="d-flex flex-row justify-content-between ">

              <div className="d-flex flex-row">
                <div className=" main-menu-item-image">
                  {item.icon}
                </div>
                <div className="main-menu-item-content">
                  <div className="main-menu-item-title pt-3 pb-1 font-weight-700">{item.title}</div>
                </div>
              </div>
              <div className=" main-menu-item-arrow-icon">
                <IoChevronBack  size={17} color={"#657180"}/>
              </div>
            </div>
            <div className="row">
              <p>{item.description}</p>
            </div>
          </Link>
        </div>
      )
    }
    return(
      <div className=" container">
        <div className="row">

          {
            items.map(item => {
              return(<MenuItem item={item} /> )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="row g-gs p-3">
                  <SupportSegment/>
                  <MainMenuHeader title={"Users"}/>
                  <MainMenu items={menu1}/>
                  <MainMenuHeader title={"Other"}/>
                  <MainMenu items={menu2}/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  );
};

export default CliconsultHome;
