import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { LuUser } from "react-icons/lu";
import { MdOutlineMeetingRoom } from "react-icons/md";

export const tableStatics = {
  title: "Patients",
  description: "",
}
export const formStatics = {
  title: "create patient",
  editTitle: "edit patient",
  description: "From here you can add new patient",
  editDescription: "From here you can edit new patient",
  submitText: "create patient",
  editSubmitText: "edit patient",
}




export const filterStructure = [
  {
    title: "Email",
    slug: 'email',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "First Name",
    slug: 'firstName',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "Last Name",
    slug: 'lastName',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "Mobile",
    slug: 'mobileNumber',
    type: "text",
    value: "",
    selected: false
  },
]

// "subjectId": "a108cc40-7014-4d23-b8a4-515acb5deca1",
//   "email": "abdiarian4@gmail.com",
//   "firstName": "arian",
//   "lastName": "abdi",
//   "dateOfBirth": "2002-05-22",
//   "mobileNumber": "09121112233",
//   "image": "e2904cad-2db8-4a0b-8ace-66e0813823aa.jpg"

export const tableStructure = [
  {
    title: "Email",
    slug: 'email',
  },
  {
    title: "First Name",
    slug: 'firstName',
  },
  {
    title: "Last Name",
    slug: 'lastName',
  },
  {
    title: "Mobile Number",
    slug: 'mobileNumber',
  },
  {
    useActionsButton: true, //use delete-item class to automatic implement the removing item.
    slug: '',
    actions: [
      // {
      //   title: "Edit patient",
      //   slug: "edit-patient",
      //   icon: <TbEdit size={18} color={"#526484"}/>,
      //
      //   useRoute: true,
      //   route: "/patient-edit/:subjectId"
      // },

      {
        title: "Show History",
        slug: 'confirm-patient',
        icon: <TbEdit size={18} color={"#526484"}/>,

        useYesOrNoModal: true,
        modalCancelText: 'Close',
        modalSubmitText: 'Confirm patient',
        modalTitle: 'Confirm patient',
        modalContent: 'By confirm this user, he/she will be able to access his/her account',
        modalType: "edit",
        modalMethod: "put",
        modalSuccessMessage: "This patient successfully approved",
        modalPath: '/api/v1/admin/patient/confirm/:subjectId',

      },
    ]
  },
]




export const formStructure = [
  [
    {
      title: "First name",
      slug: 'firstName',
      type: "text",
      isJalali: true,
      placeholder: "Please enter firstName",
      isRequired: true,
    },
    {
      title: "Last Name",
      slug: 'lastName',
      type: "text",
      placeholder: "Please enter lastName",
      isRequired: true,
    },
  ],
  [
    {
      title: "Email",
      slug: 'email',
      type: "text",
      isJalali: true,
      placeholder: "Please enter email address",
      isRequired: true,
    },
    {
      title: "Mobile Number",
      slug: 'mobileNumber',
      type: "text",
      placeholder: "Please enter email address",
      isRequired: true,
    },
  ],
  [
    {
      title: "Type",
      slug: 'type',
      type: "select",
      options: [
        {label: 'Doctor', value: 'DOCTOR'},
      ],
      placeholder: "Please enter Type",
      isRequired: true,
    },
    {
      title: "Gender",
      slug: 'gender',
      type: "select",
      options: [
        {label: 'Female', value: 'FEMALE'},
        {label: 'Male', value: 'MALE'},
      ],
      placeholder: "Please enter Gender",
      isRequired: true,
    },
  ],
  [

    {
      title: "Birthday",
      slug: 'dateOfBirth',
      type: "date",
      placeholder: "Please enter birthday",
    },
    {
      title: "Accept Vital Card",
      slug: 'acceptVitalCard',
      type: "select",
      options: [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: ''},
      ],
      placeholder: "Select Accept Vital Card",
    },
  ],
  [
    {
      title: "Can Do Pediatrics",
      slug: 'canDoPediatrics',
      type: "select",
      options: [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: ''},
      ],
      placeholder: "Select Can Do Pediatrics",
    },
    {
      title: "Can Do Abortion",
      slug: 'canDoAbortion',
      type: "select",
      options: [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: ''},
      ],
      placeholder: "Select Can Do Abortion",
    }
  ],
  [

    {
      title: "Can Do Ultrasound",
      slug: 'canDoUltrasound',
      type: "select",
      options: [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: ''},
      ],
      placeholder: "Select Can Do Ultrasound",
    },
    {
      isEmpty: true
    },
  ],
  [
    {
      title: "RPPS Number",
      slug: 'rppsNumber',
      type: "text",
      placeholder: "Please enter RPPS Number",
    },
    {
      title: "ADELI Number",
      slug: 'adeliNumber',
      type: "text",
      placeholder: "Please enter ADELI Number",
    },
  ],
  [
    {
      title: "Specialization",
      slug: 'specialization',
      type: "select",
      options: [
        {label: 'Médecine générale', value: 'GENERAL_PRACTITIONER'},
        {label: 'Gynécologie', value: 'GYNECOLOGY'},
        {label: 'Pédiatrie', value: 'PEDIATRICS'},
        {label: 'Dentiste', value: 'DENTIST'},
        {label: 'Radiologie', value: 'RADIOLOGY'},
        {label: 'Cardiologie', value: 'CARDIOLOGY'},
        {label: 'Gastro-enterologie', value: 'GASTROENTEROLOGY'},
        {label: 'Endocrinologie/Diabétologie', value: 'ENDOCRINOLOGY_DIABETOLOGY'},
      ],
      placeholder: "Please enter Specialization",
    },
    {
      title: "Office Address",
      slug: 'officeAddress',
      type: "text",
      placeholder: "Please enter Office Address",
    }
  ],
  [

    {
      title: "Doctor or Professor",
      slug: 'doctorOrProfessor',
      type: "select",
      options: [
        {label: 'Professor', value: 'PROFESSOR'},
        {label: 'doctor', value: 'DOCTOR'},
      ],
      placeholder: "Please enter if is Professor",

    },
    {
      title: "Office Phone Number",
      slug: 'officePhoneNumber',
      type: "text",
      placeholder: "Please enter Office Phone Number",
      isRequired: true,
    },
  ],


]

