import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { LuUser } from "react-icons/lu";

export const tableStatics = {
  title: "Appointments",
  description: "",
}
export const formStatics = {
  title: "create appointment",
  editTitle: "edit appointment",
  description: "From here you can add new appointment",
  editDescription: "From here you can edit new appointment",
  submitText: "create appointment",
  editSubmitText: "edit appointment",
}


export const filterStructure = [
  {
    title: "Email",
    slug: 'email',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "First Name",
    slug: 'firstName',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "Last Name",
    slug: 'lastName',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "RPPS Number",
    slug: 'rppsNumber',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "ADELI Number",
    slug: 'adeliNumber',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "Specialization",
    slug: 'specialization',
    type: "select",
    options: [
      {label: 'Médecine générale', value: 'GENERAL_PRACTITIONER'},
      {label: 'Gynécologie', value: 'GYNECOLOGY'},
      {label: 'Pédiatrie', value: 'PEDIATRICS'},
      {label: 'Dentiste', value: 'DENTIST'},
      {label: 'Radiologie', value: 'RADIOLOGY'},
      {label: 'Cardiologie', value: 'CARDIOLOGY'},
      {label: 'Gastro-enterologie', value: 'GASTROENTEROLOGY'},
      {label: 'Endocrinologie/Diabétologie', value: 'ENDOCRINOLOGY_DIABETOLOGY'},

    ],
    value: "",
    selected: false
  },
  {
    title: "AcceptVitalCard",
    slug: 'acceptVitalCard',
    type: "select",
    options: [
      {label: 'Yes', value: 'true'},
      {label: 'No', value: ''},
    ],
    value: "",
    selected: false
  },
  {
    title: "canDoPediatrics",
    slug: 'canDoPediatrics',
    type: "select",
    options: [
      {label: 'Yes', value: 'true'},
      {label: 'No', value: ''},
    ],
    value: "",
    selected: false
  },
  {
    title: "canDoAbortion",
    slug: 'canDoAbortion',
    type: "select",
    options: [
      {label: 'Yes', value: 'true'},
      {label: 'No', value: ''},
    ],
    value: "",
    selected: false
  },
  {
    title: "canDoUltrasound",
    slug: 'canDoUltrasound',
    type: "select",
    options: [
      {label: 'Yes', value: 'true'},
      {label: 'No', value: ''},
    ],
    value: "",
    selected: false
  },
]

// sessionId	"538683977232112851"
// appointmentId	"538732189267318172"
// turnNumber	1
// turnsToAwait	0
// visitTime	"15:49"
// status	"WAITING"
// fullName	"fff ddd"
// age	0
// mobileNumber	"444444444"
// clientSubjectId	""
// numOfPersons	2
// providerFullName	"Maëlys Leclerc"
// providerSpecialization	"GASTROENTEROLOGY"
// appointmentDate	"2024-01-26"
// providerGender	"FEMALE"
// doctorOrProfessor	"DOCTOR"


// fff ddd
// 0
// 15:49
// 444444444
// 1
//
// En attente
//
// 2

export const tableStructure = [
  {
    title: "Full Name",
    slug: 'fullName',
  },
  {
    title: "Turn Number",
    slug: 'turnNumber',
  },
  {
    title: "Age",
    slug: 'age',
  },
  {
    title: "Visit Time",
    slug: 'visitTime',
  },
  {
    title: "Status",
    slug: 'status',
  },
  {
    title: "Mobile Number",
    slug: 'mobileNumber',
  },
  {
    title: "Num Of Persons",
    slug: 'numOfPersons',
  },
  {
    useActionsButton: true, //use delete-item class to automatic implement the removing item.
    slug: '',
    actions: [
      // {
      //   title: "Cancel Appointment",
      //   slug: "cancel-appointment",
      //   icon: <TbEdit size={18} color={"#526484"}/>,
      //
      //   useRoute: true,
      //   route: "/appointment-edit/:subjectId"
      // },
      {
        title: "Cancel Appointment",
        slug: 'cancel-appointment',
        icon: <TbEdit size={18} color={"#526484"}/>,

        useYesOrNoModal: true,
        modalCancelText: 'Close',
        modalSubmitText: 'Cancel Appointment',
        modalTitle: 'Cancel Appointment',
        modalContent: 'By Canceling this Appointment, patient will not be able to use Appointment',
        modalType: "cancel-appointment",
        modalMethod: "put",
        modalSuccessMessage: "This appointment successfully canceled",
        modalPath: '/api/v1/admin/appointment/cancel',

      }
    ]
  },
]




export const formStructure = [
  [
    {
      title: "First name",
      slug: 'firstName',
      type: "text",
      isJalali: true,
      placeholder: "Please enter firstName",
      isRequired: true,
    },
    {
      title: "Last Name",
      slug: 'lastName',
      type: "text",
      placeholder: "Please enter lastName",
      isRequired: true,
    },
  ],
  [
    {
      title: "Email",
      slug: 'email',
      type: "text",
      isJalali: true,
      placeholder: "Please enter email address",
      isRequired: true,
    },
    {
      title: "Mobile Number",
      slug: 'mobileNumber',
      type: "text",
      placeholder: "Please enter email address",
      isRequired: true,
    },
  ],
  [
    {
      title: "Type",
      slug: 'type',
      type: "select",
      options: [
        {label: 'Doctor', value: 'DOCTOR'},
      ],
      placeholder: "Please enter Type",
      isRequired: true,
    },
    {
      title: "Gender",
      slug: 'gender',
      type: "select",
      options: [
        {label: 'Female', value: 'FEMALE'},
        {label: 'Male', value: 'MALE'},
      ],
      placeholder: "Please enter Gender",
      isRequired: true,
    },
  ],
  [

    {
      title: "Birthday",
      slug: 'dateOfBirth',
      type: "date",
      placeholder: "Please enter birthday",
    },
    {
      title: "Accept Vital Card",
      slug: 'acceptVitalCard',
      type: "select",
      options: [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: ''},
      ],
      placeholder: "Select Accept Vital Card",
    },
  ],
  [
    {
      title: "Can Do Pediatrics",
      slug: 'canDoPediatrics',
      type: "select",
      options: [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: ''},
      ],
      placeholder: "Select Can Do Pediatrics",
    },
    {
      title: "Can Do Abortion",
      slug: 'canDoAbortion',
      type: "select",
      options: [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: ''},
      ],
      placeholder: "Select Can Do Abortion",
    }
  ],
  [

    {
      title: "Can Do Ultrasound",
      slug: 'canDoUltrasound',
      type: "select",
      options: [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: ''},
      ],
      placeholder: "Select Can Do Ultrasound",
    },
    {
      isEmpty: true
    },
  ],
  [
    {
      title: "RPPS Number",
      slug: 'rppsNumber',
      type: "text",
      placeholder: "Please enter RPPS Number",
    },
    {
      title: "ADELI Number",
      slug: 'adeliNumber',
      type: "text",
      placeholder: "Please enter ADELI Number",
    },
  ],
  [
    {
      title: "Specialization",
      slug: 'specialization',
      type: "select",
      options: [
        {label: 'Médecine générale', value: 'GENERAL_PRACTITIONER'},
        {label: 'Gynécologie', value: 'GYNECOLOGY'},
        {label: 'Pédiatrie', value: 'PEDIATRICS'},
        {label: 'Dentiste', value: 'DENTIST'},
        {label: 'Radiologie', value: 'RADIOLOGY'},
        {label: 'Cardiologie', value: 'CARDIOLOGY'},
        {label: 'Gastro-enterologie', value: 'GASTROENTEROLOGY'},
        {label: 'Endocrinologie/Diabétologie', value: 'ENDOCRINOLOGY_DIABETOLOGY'},
      ],
      placeholder: "Please enter Specialization",
    },
    {
      title: "Office Address",
      slug: 'officeAddress',
      type: "text",
      placeholder: "Please enter Office Address",
    }
  ],
  [

    {
      title: "Doctor or Professor",
      slug: 'doctorOrProfessor',
      type: "select",
      options: [
        {label: 'Professor', value: 'PROFESSOR'},
        {label: 'doctor', value: 'DOCTOR'},
      ],
      placeholder: "Please enter if is Professor",

    },
    {
      title: "Office Phone Number",
      slug: 'officePhoneNumber',
      type: "text",
      placeholder: "Please enter Office Phone Number",
      isRequired: true,
    },
  ],


]

