import React from "react";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import Icon from "../icon/Icon";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

const PaginationComponent = ({ itemPerPage, totalItems, paginate, currentPage, pageIds }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginationNumber = () => {
    if(pageNumbers.length <= 5){
      return pageNumbers;
    }else if(pageNumbers.length >= 5 && currentPage <= 4){
      return [1,2,3,4,5,'...',pageNumbers[pageNumbers.length - 1]];
    }else if(pageNumbers.length >= 5 && currentPage >= pageNumbers[pageNumbers.length - 4]){
      return [1,'...',pageNumbers[pageNumbers.length - 5],pageNumbers[pageNumbers.length - 4],pageNumbers[pageNumbers.length - 3],pageNumbers[pageNumbers.length - 2],pageNumbers[pageNumbers.length - 1]];
    }else if(pageNumbers.length > 5 && currentPage > 4 && currentPage < pageNumbers[pageNumbers.length - 4]){
      return [1,'...',currentPage-1,currentPage,currentPage+1,'...',pageNumbers[pageNumbers.length - 1]];
    }
  };

  let paginationItms = paginationNumber();

  const firstPage = () => {
    paginate(1);
  };

  const lastPage = () => {
    paginate(pageNumbers[pageNumbers.length - 1]);
  };

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  return (
    <Pagination aria-label="Page navigation example">
      {/*<PaginationItem disabled={currentPage - 1 === 0 ? true : false}>*/}
      {/*  <PaginationLink*/}
      {/*    className="page-link-first"*/}
      {/*    onClick={(ev) => {*/}
      {/*      ev.preventDefault();*/}
      {/*      firstPage();*/}
      {/*    }}*/}
      {/*    href="#first"*/}
      {/*  >*/}
      {/*    <FiChevronsLeft size={17} color={"#526484"}/>*/}
      {/*  </PaginationLink>*/}
      {/*</PaginationItem>*/}
      <PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
        <PaginationLink
          className="page-link-prev"
          onClick={(ev) => {
            if(currentPage - 1 !== 0 ){
              ev.preventDefault();
              prevPage();
            }
          }}
          href="#prev"
        >

          <FiChevronLeft size={17} color={"#526484"}/>
        </PaginationLink>
      </PaginationItem>
      {/*{paginationItms.map((item) => {*/}
      {/*  return (*/}
      {/*    <PaginationItem  disabled={isNaN(item)} className={`d-none d-sm-block ${currentPage === item ? "active" : ""}`} key={item}>*/}
      {/*      <PaginationLink*/}
      {/*            tag="a"*/}
      {/*            href="#pageitem"*/}
      {/*        onClick={(ev) => {*/}
      {/*          ev.preventDefault();*/}
      {/*              paginate(item);*/}
      {/*        }}*/}
      {/*      >*/}
      {/*            {item}*/}
      {/*      </PaginationLink>*/}
      {/*    </PaginationItem>*/}
      {/*  );*/}
      {/*})}*/}
      <PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === currentPage || !pageIds.nextPage}>
        <PaginationLink
          className="page-link-next"
          onClick={(ev) => {
            if(!(pageNumbers[pageNumbers.length - 1] === currentPage || !pageIds.nextPage)){
              ev.preventDefault();
              nextPage();
            }

          }}
          href="#next"
        >

          <FiChevronRight size={17} color={"#526484"}/>
        </PaginationLink>
      </PaginationItem>

      <div>
        Page: {currentPage}
      </div>
      {/*<PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === currentPage}>*/}
      {/*  <PaginationLink*/}
      {/*    className="page-link-next"*/}
      {/*    onClick={(ev) => {*/}
      {/*      ev.preventDefault();*/}
      {/*      lastPage();*/}
      {/*    }}*/}
      {/*    href="#last"*/}
      {/*  >*/}

      {/*    <FiChevronsRight  size={17} color={"#526484"}/>*/}
      {/*  </PaginationLink>*/}
      {/*</PaginationItem>*/}
    </Pagination>
  );
};
export default PaginationComponent;
