import { TbEdit } from "react-icons/tb";
import { RiCalendar2Line, RiDeleteBinLine } from "react-icons/ri";
import { LuUser } from "react-icons/lu";
import { MdOutlineMeetingRoom } from "react-icons/md";

export const tableStatics = {
  title: "Support",
  description: "",
}
export const formStatics = {
  title: "create Support",
  editTitle: "edit Support",
  description: "From here you can add new Support",
  editDescription: "From here you can edit new Support",
  submitText: "create Support",
  editSubmitText: "edit Support",
}


export const filterStructure = [
  {
    title: "Email",
    slug: 'email',
    type: "text",
    value: "",
    selected: false
  },
  {
    title: "Date",
    slug: 'date',
    type: "date",
    value: "",
    selected: false
  },
]



export const tableStructure = [
  {
    title: "Email",
    slug: 'email',
  },
  {
    title: "Title",
    slug: 'customerName',
  },
  {
    title: "Issue Date",
    slug: 'issueDate',
  },
  {
    title: "Pattern",
    slug: 'pattern',
  },
  {
    useActionsButton: true, //use delete-item class to automatic implement the removing item.
    slug: '',
    actions: [
      {
        title: "Detail",
        slug: "detail",
        icon: <TbEdit size={18} color={"#526484"}/>,

        useRoute: true,
        route: "/support/:issueDate/:email"
      },

    ]
  },
]






