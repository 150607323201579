import React, { useEffect, useState } from "react";
import Form, { FormIsLoading } from "../../../components/fouladyar/form";
import { useDispatch, useSelector } from "react-redux";
import { formStatics, formStructure } from "./index";
import axios from "axios";
import { convertDate } from "../../../shared/shared";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getItemById } from "../../../redux/store/services/asoo/teachers/store/teachers-actions";
import toast from "react-hot-toast";
import { ErrorToaster } from "../../../shared/toaster";
import { useTranslation } from "react-i18next";
import { ConvertFilterObjectToUrlParam } from "../../../redux/store/shared/shared";
import moment from "moment";



function SessionAdd({ ...props }) {

  const { t, i18n } = useTranslation();
  const { providerId, date, sessionId} = useParams();
  const location = useLocation();
  const isEditing = location.pathname.includes("session-edit");
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const path = '/Persons/Teacher'
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [isLoading, setIsloading] = useState(isEditing ? true : false);


  const [data, setData] = useState({
    "providerSubjectId": "",
    "date": "",
    "start": "",
    "end": "",
    "length": 0,

  });

  async function getItemById(){
    try {

      return (await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/session/${providerId}/${date}`,{
        headers: {
          authorization: `bearer ${auth.token}`
        }
      }));
    } catch (e) {
      ErrorToaster(e, t)
    }
  }

  async function loadData() {
    try {
      const res = await getItemById();
      if (res.status === 200) {

        console.log('getItemById', res.data.find(i => i.id ===sessionId ))
        const _data = res.data.find(i => i.id ===sessionId );

        setData({
          ..._data,
          providerSubjectId: providerId
        });
        //
        setIsloading(false);

      }

    }catch (e){
      ErrorToaster(e, t)
    }


  }


  useEffect(() => {
    if (isEditing)
      loadData();
    else
      setData(prevState => ({
        ...prevState,
        date: new Date(date),
        providerSubjectId: providerId
      }))
  }, []);





  function checkIsRequiredFields() {

    const ErrorMessages = [];

    if(!data.start)
      ErrorMessages.push("start is required");
    if(!data.end)
      ErrorMessages.push("end is required");
    if(!data.length)
      ErrorMessages.push("length is required");




    if(ErrorMessages.length > 0){
      toast.error(ErrorMessages.join('\n'))
      return false
    }

    return true
  }
  async function onCreate() {
    try {

      if(checkIsRequiredFields()){
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/session`,
          {
            ...data,
            date: convertDate(data.date)
          }, {
            headers: {
              "authorization": `bearer ${auth.token}`
            }
          }
        );

        if(res.status === 201) {
          navigate(`/session-list/${providerId}`);
          setProcessing(false)
        }
      }



    } catch (e) {
      ErrorToaster(e, t)
      setProcessing(false)
    }
  }

  async function onUpdate() {
    try {

      setProcessing(true)
      if(checkIsRequiredFields()){
        let _data = {...data}

        delete _data.status
        delete _data.id

        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/admin/session`,
          {
            ..._data,
            sessionId: sessionId
          }, {
            headers: {
              "authorization": `bearer ${auth.token}`
            }
          }
        );

        console.log('res', res)
        if(res.status === 200) {
          navigate(`/session-list/${providerId}`);
          setProcessing(false)
        }

      }


    } catch (e) {
      ErrorToaster(e, t)
    }
  }

  function handleOnFieldChange(change) {
    setData((prevData) => ({
      ...prevData,
      ...change
    }));
  }

  async function handleOnSubmit() {
    console.log("form", data);
    !isEditing ? await onCreate() : await onUpdate();

  }

  return (

    <>
      {
        isLoading ?
          <FormIsLoading
            statics={formStatics}
            isEditing={isEditing}
          />
          :
          <Form
            form={data}
            isEditing={isEditing}
            fields={formStructure}
            statics={formStatics}
            isloading={processing}
            onFieldChange={handleOnFieldChange}
            onFormSubmit={handleOnSubmit}
          />
      }
    </>


  );
};

export default SessionAdd;
