import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { LuUser } from "react-icons/lu";

export const tableStatics = {
  title: "Sessions",
  description: "",
  addNewItemButtonLink: '/session-add'
}
export const formStatics = {
  title: "create session",
  editTitle: "edit session",
  description: "From here you can add new session",
  editDescription: "From here you can edit new session",
  submitText: "create session",
  editSubmitText: "edit session",
}


export const filterStructure = [

]
// id	"538683916845918987"
// date	"2024-01-26"
// start	"14:00"
// end	"15:00"
// length	4
// status	"EXPIRED"


export const tableStructure = [
  {
    title: "Start",
    slug: 'start',
  },
  {
    title: "End",
    slug: 'end',
  },
  {
    title: "Length",
    slug: 'length',
  },
  {
    title: "Status",
    slug: 'status',
  },
  {
    useActionsButton: true, //use delete-item class to automatic implement the removing item.
    slug: '',
    actions: [
      {
        title: "Edit session",
        slug: "edit-session",
        icon: <TbEdit size={18} color={"#526484"}/>,

        useRoute: true,
        route: "/session-edit/:subjectId"
      },
      {
        title: "Delete session",
        slug: 'delete-session',
        icon: <TbEdit size={18} color={"#526484"}/>,

        useYesOrNoModal: true,
        modalCancelText: 'Delete',
        modalSubmitText: 'Delete session',
        modalTitle: 'Delete session',
        modalContent: 'By Deleting this session, patient will not be able to use session',
        modalType: "delete-session",
        modalMethod: "delete",
        modalSuccessMessage: "This session successfully deleted",
        modalPath: '/api/v1/admin/session/cancel',

      }
    ]
  },
]


// "providerSubjectId": "providerSubjectId_31ae9146c363",
//   "date": "2024-01-05",
//   "start": "11:02:24",
//   "end": "11:02:24",
//   "length": 0

export const formStructure = [
  [
    {
      title: "Provider Subject Id",
      slug: 'providerSubjectId',
      type: "text",
      placeholder: "Please enter Provider Subject Id",
    },
    {
      title: "Date",
      slug: 'date',
      type: "date",
      placeholder: "Please enter date",
    },
  ],
  [

    {
      title: "Start",
      slug: 'start',
      type: "time",
      placeholder: "Please enter Start Time",
    },
    {
      title: "End",
      slug: 'end',
      type: "time",
      placeholder: "Please enter End Time",
    },
  ],
  [

    {
      title: "Length",
      slug: 'length',
      type: "number",
      placeholder: "Please enter length Time",
    },
    {
      isEmpty: true
    },
  ],


]

