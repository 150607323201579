import { RiBankLine, RiBook2Line, RiCalendar2Line, RiNurseLine } from "react-icons/ri";
import { BiChalkboard, BiGroup } from "react-icons/bi";
import { MdOutlineHome, MdOutlineHomeRepairService, MdOutlineMeetingRoom } from "react-icons/md";
import { PiChalkboardTeacher, PiChalkboardTeacherBold, PiStudentBold } from "react-icons/pi";
import { LuGraduationCap, LuUsers, LuUserSquare } from "react-icons/lu";
import { TbPlane, TbPlaneInflight, TbSettings } from "react-icons/tb";
import { LiaPlaneDepartureSolid, LiaPlaneSolid } from "react-icons/lia";
import { FiUser } from "react-icons/fi";
import { GrUserManager } from "react-icons/gr";
import { CgSupport } from "react-icons/cg";

const fontSize = 19;
const color = "#6e82a5";
const menu = [
  {
    heading: "USERS"
  },

  {
    icon: <RiNurseLine size={fontSize} color={color} />,
    text: "Providers",
    active: false,
    subMenu: [
      {
        text: "Doctors",
        link: "/provider-list/DOCTOR",
      },
      {
        text: "Nurses",
        link: "/provider-list/NURSE",
      },
      {
        text: "Midwife",
        link: "/provider-list/MIDWIFE",
      }
    ]
  },
  {
    icon: <GrUserManager size={fontSize+1} color={color} className="stroke-custom" />,
    text: "Patient",
    link: "/patient-list",
  },
  {
    icon: <CgSupport  size={fontSize+1} color={color} />,
    text: "Support",
    link: "/support-list",
  }
];




export default menu;
