import React from "react";
import { Link } from "react-router-dom";
import { Block, BlockContent, Button } from "../../../components/Component";

const Error404Classic = () => {
  return (
    <>
      <Block className="nk-block-middle wide-xs mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <h1 className="nk-error-head">404</h1>
          <h3 className="nk-error-title">The desired page was not found</h3>
          <p className="nk-error-text">
            We are very sorry for this problem. It looks like you are trying to access a page that has either been removed or never existed
          </p>
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <Button color="primary" size="lg" className="mt-2">
              Return to main page
            </Button>
          </Link>
        </BlockContent>
      </Block>
    </>
  );
};
export default Error404Classic;
