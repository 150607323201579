import React, { useEffect, useMemo, useState } from "react";
import Table from "../../../components/fouladyar/table";
import { useDispatch, useSelector } from "react-redux";
import { filterStructure, tableStatics, tableStructure } from "./index";
import { ConvertFilterObjectToUrlParam } from "../../../redux/store/shared/shared";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ErrorToaster } from "../../../shared/toaster";
import { useTranslation } from "react-i18next";
import ProviderSelector from "./components/providerSelector";
import moment from "moment";






const AppointmentsList = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  const { providerId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState(null)
  const [sessions, setSessions] = useState(null)
  const [selectedSession, setSelectedSession] = useState()
  const [nextPage, setNextPage] = useState(null)
  const [prevPage, setPrevPage] = useState(null)
  const [date, setDate] = useState();
  const [pagination, setPagination] = useState({
    itemPerPage: 7,
    currentPage: 1,
    totalItems: 0,
    lastUpdateBy: '',

    //Cliconsult pagination style
    pageId: '',

  })

  const [filter, setFilter] = useState({})
  async function getProvider(){
    try {
      console.log('providerId', providerId)
      return (await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/account/${providerId}`,{
        headers: {
          authorization: `bearer ${auth.token}`
        }
      }));

    } catch (e) {
      ErrorToaster(e, t)
    }
  }
  async function loadSessions(){
    try {
      console.log('providerId', providerId)
      return (await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/session/${providerId}/${date}`,{
        headers: {
          authorization: `bearer ${auth.token}`
        }
      }));

    } catch (e) {
      ErrorToaster(e, t)
    }
  }

  async function getItem(){
    try {
      console.log('selectedSession', selectedSession)
      return (await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/appointment/${providerId}/${selectedSession}/${date}`,{
        headers: {
          authorization: `bearer ${auth.token}`
        }
      }));
      return []
    } catch (e) {
      ErrorToaster(e, t)
    }
  }
  async function initializeData() {
    try {
      setIsLoading(true)
      const res = await getItem()

      console.log('res-initialdata', res);
      if(res.status === 200){
        console.log('initialdata', res.data);
        setData(res.data)
        //nextPage previousPage
        // setPrevPage(res.data.previousPage)
        // setNextPage(res.data.nextPage)
        // setPagination({...pagination, totalItems: res.count || 1, lastUpdateBy: 'initializer'})
      }
      setIsLoading(false)
    }catch (e) {
      setIsLoading(false)
      ErrorToaster(e,t)
    }
  }

  async function fetchData(updater) {

    try {
      setIsLoading(true)
      const res = await getItem()

      console.log('res-fetchData', res);
      if(res.status === 200){
        console.log('fetchData', res.data);
        setData(res.data)
        // setPrevPage(res.data.previousPage)
        // setNextPage(res.data.nextPage)

        if(updater === 'filter')
          setPagination({
            ...pagination,
            totalItems: res.data.count,
            currentPage: 1,
            lastUpdateBy: updater
          })
      }
      setIsLoading(false)
    }catch (e){
      toast.error(e.response.data.error || (e.response.data.message || e.message))
    }


  }

  useEffect(() => {
   async function initialize(){
     try {
       const res = await getProvider()
       console.log('provider', res);
       if(res.status === 200)
         setProvider(res.data)
       // initializeData();
     }catch (e) {

     }
   }

   initialize()
  }, []);

  useEffect(() => {
    console.log('selectedSession', selectedSession)
    if(selectedSession)
      initializeData();
  }, [selectedSession]);


  useEffect(() => {
    console.log('loadSession', date)
    async function _loadSession(){
      try {
        const res = await loadSessions();
        console.log('res', res)
        if(res.status === 200){
          setSessions(res.data)
        }
      }catch (e) {
        ErrorToaster(e, t)
      }
    }

    console.log('ddddddddddddaaaaaaaaateeeeeeeeeeeeeee', date)
    if(date)
      _loadSession()
  }, [date]);


  useEffect(() => {
    console.log(pagination);
    if(pagination.lastUpdateBy === "pagination")
        fetchData('pagination');

  }, [pagination]);



  useEffect(() => {
    console.log('filter', filter)
    // fetchData('filter');
  }, [filter]);



  return (
    <React.Fragment>

      <ProviderSelector
        provider={provider}
        sessions={sessions}
        selectedSession={selectedSession}
        date={date}
        onDateChange={(e)=>{
          // console.log('onDateChange', e)
          setDate(moment(e).format('YYYY-MM-DD'))
        }}
        onSessionChange={(e)=>{
          console.log('onSessionChange', e)
          setSelectedSession(e)
        }}
      />

      <Table
        props={{providerId: providerId}}
        loading={isLoading}
        filter={filter}
        tableData={data}
        pagination={pagination}
        pageIds={{
          nextPage: nextPage,
          prevPage: prevPage
        }}
        tableHeading={tableStatics}
        tableStructure={tableStructure}
        filterStructure={filterStructure}
        onItemPerPageChange={(itemPerPage, currentPage)=> {
          setPagination({...pagination, itemPerPage: itemPerPage, currentPage: currentPage, lastUpdateBy: 'pagination'})
        }}
        onCurrentPageChange={(currentPage)=> {
          setPagination(prevState => ({
            ...prevState,
            currentPage: currentPage,
            lastUpdateBy: 'pagination',
            pageId: prevState.currentPage < currentPage ? nextPage : prevPage
          }))

        }}
        onFilterSubmit={ (e)=>{
          setFilter(e);
        }}
        onDeleteComplete={ (e)=>{
          fetchData('pagination');
        }}
      />
    </React.Fragment>
  );
};

export default AppointmentsList;
